import React, { useEffect, useState } from 'react';
import Layout from '../elements/Layout';
import { getCustomerPayments } from '../utils/api/api';
import toast from 'react-hot-toast';
import Cookies from 'js-cookie';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { IoMdSearch } from 'react-icons/io';
import moment from 'moment';
import { FaEye } from 'react-icons/fa6';
import { Link } from 'react-router-dom';

const Payments = () => {
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const cu_id = Cookies.get('user_id');

  const getPayments = async () => {
    setLoading(true);
    try {
      const response = await getCustomerPayments(Number(cu_id));
      setPayments(response.payments);
    } catch (error) {
      toast.error(error.message || "Failed to fetch payments");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPayments();
  }, []);

  const handlePrintPDF = () => {
    const input = document.getElementById("paymentsTable");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      const imgWidth = 190; // Width of PDF page
      const pageHeight = pdf.internal.pageSize.height;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      let position = 0;

      pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      pdf.save("payments.pdf");
    });
  };

  return (
    <Layout text="Payments" backgroundColor='bg-[#FCFCFC]'>
      <div className="w-full bg-white rounded-lg shadow-lg p-6">
        <div className="flex items-center justify-between w-full mb-4">
          <div className="w-fit flex items-center gap-2 px-4 py-2 rounded-full bg-gray-100 border border-gray-300">
            <IoMdSearch className='text-gray-600' />
            <input
              type="text"
              placeholder='Search'
              className='outline-none border-none text-gray-600 bg-transparent font-light text-[14px]'
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <button
            onClick={handlePrintPDF}
            className="bg-secondary text-white px-4 py-2 rounded-lg shadow-md hover:bg-secondary-dark transition duration-150"
          >
            Download PDF
          </button>
        </div>

        <div id="paymentsTable" className="overflow-x-auto">
          <table className="min-w-full bg-white table-auto border border-gray-200 rounded-lg">
            <thead className="bg-[#F7F7F7] text-gray-700 uppercase text-sm font-medium">
              <tr>
                <th className="py-3 px-6 text-left">Transaction No.</th>
                <th className="py-3 px-6 text-left">Transaction ID</th>
                <th className="py-3 px-6 text-left">Amount (KES)</th>
                <th className="py-3 px-6 text-left">Method</th>
                <th className="py-3 px-6 text-left">Status</th>
                <th className="py-3 px-6 text-left">Date</th>
                <th className="py-3 px-6 text-left">Time</th>
                <th className="py-3 px-6 text-left">Action</th>
              </tr>
            </thead>
            <tbody className="text-gray-600 text-[14px]">
              {loading ? (
                <tr>
                  <td colSpan="8" className="py-4 text-center">Loading...</td>
                </tr>
              ) : payments.length > 0 ? (
                payments
                  .filter((payment) =>
                    payment.transaction_number.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    payment.transaction_id.toLowerCase().includes(searchTerm.toLowerCase())
                  )
                  .map((payment) => (
                    <tr key={payment.id} className="border-b border-gray-200 hover:bg-gray-50 transition duration-150">
                      <td className="py-3 px-6 text-left">{payment.transaction_number}</td>
                      <td className="py-3 px-6 text-left">{payment.transaction_id}</td>
                      <td className="py-3 px-6 text-left">{Number(payment.amount).toLocaleString()}</td>
                      <td className="py-3 px-6 text-left">{payment.method}</td>
                      <td className="py-3 px-6 text-left">
                        <span className={`px-2 py-1 rounded-full text-[12px] ${payment.status === 'completed' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
                          {payment.status}
                        </span>
                      </td>
                      <td className="py-3 px-6 text-left">{moment(payment.createdAt).format('MMM Do, YYYY')}</td>
                      <td className="py-3 px-6 text-left">{moment(payment.createdAt).format('h:mm A')}</td>
                      <td className="py-3 px-6 text-left">
                        <Link
                          className='text-secondary hover:text-secondary-dark transition duration-150'
                          to={`/dashboard/payments/${payment.id}/view-details`}
                        >
                          <FaEye size={24} />
                        </Link>
                      </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td colSpan="8" className="py-4 text-center text-gray-600">No payments found.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  );
};

export default Payments;
