import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import MyProperties from './pages/MyProperties';
import Contracts from './pages/Contracts';
import Invoices from './pages/Invoices';
import Bookings from './pages/Bookings';
import Support from './pages/Support';
import ViewContract from './pages/ViewContract';
import Referral from './pages/Referral';
import Settings from './pages/Settings';
import Notifications from './pages/Notifications';
import SetPassword from './pages/auth/SetPassword';
import Login from './pages/auth/Login';
import SignUp from './pages/auth/SignUp';
import Orders from './pages/Orders';
import Index from './pages/Index';
import Messaging from './pages/Messaging';
import HousingProjects from './pages/HousingProjects';
import ViewProject from './pages/ViewProject';
import Paymentage from './pages/Paymentage';
import ViewInvoice from './pages/ViewInvoice';
import Payments from './pages/Payments';
import ViewOrder from './pages/ViewOrder';
import Account from './pages/Account';
import WalletStatement from './minicomponents/WalletStatement';
import Receipt from './minicomponents/Receipt';
import NotFoundPage from './pages/NotFoundPage';

const AppRoutes = () => {

    const property = {
        id: 1,
        title: "Luxurious Beachfront Villa",
        location: "Diani Beach, Kenya",
        price: 15000000, // Property price in Ksh
        image: "https://example.com/villa.jpg",
        description: "A stunning 4-bedroom villa with ocean views, private pool, and direct beach access."
    };


    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Index />} />

                <Route path='/accounts/login' element={<Login />} />
                <Route path='/accounts/sign-up' element={<SignUp />} />                
                <Route path='/dashboard' element={<Dashboard />} />
                <Route path='/dashboard/my-properties' element={<MyProperties />} />
                <Route path='/dashboard/contracts' element={<Contracts />} />
                <Route path='/dashboard/contracts/:id/view-details' element={<ViewContract />} />
                <Route path='/dashboard/invoices' element={<Invoices />} />
                <Route path='/dashboard/bookings' element={<Bookings />} />
                <Route path='/dashboard/support' element={<Support />} />
                <Route path='/dashboard/referral' element={<Referral />} />
                <Route path='/dashboard/settings' element={<Account />} />
                <Route path='/dashboard/notifications' element={<Notifications />} />
                <Route path="/customer/set-password" element={<SetPassword />} />
                <Route path='/dashboard/orders' element={<Orders />} />
                <Route path='/dashboard/orders/:id/view' element={<ViewOrder property={property} />} />
                <Route path='/dashboard/messaging' element={<Messaging />} />
                <Route path='/dashboard/projects' element={<HousingProjects />} />
                <Route path='/dashboard/projects/:id/view' element={<ViewProject />} />

                <Route path='/dashboard/orders/:id/pay' element={<Paymentage />} />
                <Route path='/dashboard/invoices/:id/view' element={<ViewInvoice />} />
                <Route path='/dashboard/payments' element={<Payments />} />
                <Route path='/dashboard/payments/:id/view-details' element={<Receipt />} />
                <Route path='/dashboard/statement' element={<WalletStatement />} />

                <Route path="*" element={<NotFoundPage />} />
            </Routes>
        </BrowserRouter>
    );
};

export default AppRoutes;
