import React, { useEffect, useState } from 'react'
import Layout from '../elements/Layout'
import { useNavigate, useParams } from 'react-router-dom';
import { createCustomBooking, createOrder, fetchProjectById, fetchProperties } from '../utils/api/api';
import Cookies from 'js-cookie';
import toast from 'react-hot-toast';
import img from '../assets/img.jpeg'
import Modal from '../elements/Modal';
import Loading from '../elements/Loading';
import ProjectImages from './ProjectImages'

const ViewProject = () => {
  const [project, setProject] = useState([]);
  const [loading, setLoading] = useState(false);
  const [properties, setProperties] = useState([]);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [paymentType, setPaymentType] = useState('one_time'); 
  const { id } = useParams();
  const project_id = id;
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    project_id: project_id,
    description: '',
    date: '',
    time: '',
  });

  const customerId = Cookies.get('user_id');
  const times = ['8:30', '9:30', '10:30', '11:30', '12:30', '1:30', '2:30', '3:30', '4:30'];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleExpressInterest = (property) => {
    setSelectedProperty(property);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedProperty(null);
    setPaymentType('one_time');
  };

  const handleSubmitOrder = async () => {
    const orderPayload = {
      customer_id: customerId,
      property_id: selectedProperty.id,
      amount: paymentType === 'one_time' ? project.price : project.installment_price,
      payment_type: paymentType,
    };
    
    setLoading(true);
    try {
      const response = await createOrder(orderPayload);
      handleCloseModal();
      toast.success('Order placed');
      navigate('/dashboard/orders')
    } catch (error) {
      toast.error('Failed to place order')
    } finally {
      setLoading(false);
    }    
  };

  const handleTimeSelect = (selectedTime) => {
    setFormData((prevData) => ({
      ...prevData,
      time: selectedTime,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setOpen(false);
    const bookingData = {
      customer_id: customerId,
      project_id: formData.project_id,
      description: formData.description,
      date: formData.date,
      time: formData.time,
    };

    try {
      await createCustomBooking(bookingData);
      toast.success('Custom booking created');
      navigate('/dashboard/bookings');
    } catch (error) {
      toast.error('Failed to create booking');
    } finally {
      setLoading(false);
    }
  };

  const getProject = async () => {
    setLoading(true);
    try {
      const data = await fetchProjectById(project_id);
      setProject(data.project);
      setLoading(false);
    } catch (err) {
      toast.error("Request failed.")
      setLoading(false);
    }
  };

  const getProperties = async () => {
    try {
      const response = await fetchProperties(project_id);
      setProperties(response.properties)
    } catch (error) {
      toast.error("Failed to get properties")
    }
  }

  useEffect(() => {
    getProject();
    getProperties();
  }, [project_id]);
  return (
    <Layout text="View">
      {loading && <Loading />}
      <p className="mb-4 text-[32px] font-semibold text-black">{project.title}</p>
      <img src={img} alt="" />
      <ProjectImages images={project.images} />
      <div className="flex flex-col md:flex-row gap-4 mt-4">
        <div className="flex flex-col gap-3 w-full md:w-[65%]">
          <div className="flex flex-col">
            <p className="text-[18px] font-medium mb-2">About this property</p>
            <p className="text-black font-light text-[14px]">{project.description}</p>
          </div>
          <div className="">
            <p className="text-[18px] font-medium mb-2">Amenities</p>
            <div className="flex w-full gap-3 flex-wrap">
              <div className="flex flex-wrap gap-2">
                {project?.amenities?.split(',').map((amenity, index) => (
                  <div key={index} className="rounded-md px-4 py-1.5 bg-white flex items-center gap-2 text-black font-light text-[14px]">
                    <div className="h-10 w-10 rounded-full bg-secondary"></div>
                    {amenity.trim()}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="p-4 bg-white w-full md:w-[35%] h-fit rounded-md ">
          <div className="flex w-full justify-between items-center">
            <p className="text-[14px] font-light">Size</p>
            <p className="text-[14px]">{project.size}</p>
          </div>
          <div className="flex w-full mt-2 justify-between items-center">
            <p className="text-[14px] font-light">Status</p>
            <p className="text-[14px]">{project.status}</p>
          </div>
          <div className="flex w-full border-t border-gray-200 mt-4 pt-4 justify-between items-center">
            <p className="text-[14px] font-light">Cash Price</p>
            <p className="text-[14px]">Kes. {Number(project.price).toLocaleString("KES")}</p>
          </div>
          <div className="flex w-full justify-between items-center mt-2">
            <p className="text-[14px] font-light">Installment price</p>
            <p className="text-[14px]">
              Kes. {
                Number(project.installment_price).toLocaleString("KES")
              }
            </p>
          </div>
          <div className="flex w-full justify-between items-center mt-2">
            <p className="text-[14px] font-light">Deposit</p>
            <p className="text-[14px]">Kes. {Number(project.deposit).toLocaleString("KES")}</p>
          </div>
          <div className="flex w-full justify-between items-center mt-2">
            <p className="text-[14px] font-light">Monthly Installments</p>
            <p className="text-[14px]">Kes. {Number(project.monthly_installment).toLocaleString("KES")}</p>
          </div>
          <div className="mt-2 w-full justify-end flex items-center gap-3">

            <button 
              onClick={() => setOpen(true)}
              className="border border-secondary rounded-md text-secondary text-[14px] font-medium px-6 py-1.5"
            >
              Book site visit
            </button>
          </div>
        </div>
      </div>

      <div className="w-full bg-white p-4 rounded-lg mt-4">
        <p className="text-[22px] font-medium text-black mb-2">Properties</p>
        <div className="overflow-x-auto rounded-lg">
          <table className="min-w-full bg-white h-full flex-grow rounded-lg table-auto">
            <thead>
              <tr className="text-primary border-b pb-4 text-[14px] py-3 border-t border-primary font-medium leading-normal">
                <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Title number</th>
                <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Status</th>
                <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">size</th>
                <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Action</th>
              </tr>
            </thead>
            <tbody className="text-primary text-[14px] font-light">
              {properties.length === 0 ? (
                <tr>
                  <td colSpan="7" className="py-4 px-6 text-center text-gray-500">
                    {loading ? 'Loading, please wait' : 'No data found'}
                  </td>
                </tr>
              ) : (
                properties.map((property, index) => (
                  <tr
                    key={index}
                    className={`text-[14px] font-light text-black border-b border-gray-200 cursor-pointer hover:bg-gray-50 ${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                      }`}
                  >
                    <td className="py-4 px-6 text-left whitespace-nowrap">
                      <div className="flex gap-2 items-center">
                        {property.title_number}
                      </div>
                    </td>
                    <td className="py-4 px-6 text-left whitespace-nowrap">
                      <span
                        className={`${property.status === 'not_started' ? 'text-gray-500 bg-gray-100 py-1 px-2 rounded' :
                          property.status === 'under_contract' ? 'text-yellow-500 bg-yellow-100 p-1 px-2 rounded-full' :
                            property.status === 'available' ? 'text-green-500 bg-green-100 py-1 px-2 rounded-full' :
                              property.status === 'sold' ? 'text-red-500 bg-red-100 py-1 px-2 rounded-full' : 'text-black bg-white'
                          }`}
                      >
                        {property.status.replace('_', ' ')}
                      </span>
                    </td>
                    <td className="py-4 px-6 text-left whitespace-nowrap">
                      <span>{property.size}</span>
                    </td>
                    <td className="py-4 px-6 text-left whitespace-nowrap">
                      {property.status === 'available' ? (
                        <button 
                          onClick={() => handleExpressInterest(property)}
                          className="text-white bg-secondary font-medium text-[14px] px-3 py-1 rounded-full"
                        >
                          Express Interest
                        </button>
                      ) : (
                        <span className="text-gray-500 bg-gray-200 px-3 py-1 rounded-md cursor-not-allowed">
                          {property.status.replace('_', ' ')}
                        </span>
                      )}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>

      {open && (
        <Modal title="Book site visit" onClose={() => setOpen(false)}>
          <form onSubmit={handleSubmit} className="w-full">
            <div className="mb-4">
              <label htmlFor="description" className="block text-black font-medium mb-1">
                Description <span className="text-secondary">*</span>
              </label>
              <textarea
                id="description"
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                className="border w-full border-gray-200 text-gray-600 p-2 rounded-md outline-none focus:border-secondary"
                rows="4"
                placeholder="Enter description"
              />
            </div>

            <div className="mb-4">
              <label htmlFor="date" className="block text-black font-medium mb-1">
                Date <span className="text-secondary">*</span>
              </label>
              <input
                type="date"
                id="date"
                name="date"
                value={formData.date}
                onChange={handleInputChange}
                className="border w-full border-gray-200 text-gray-600 p-2 rounded-md outline-none focus:border-secondary"
              />
            </div>

            <div className="mb-4">
              <label className="block text-black font-medium mb-1">
                Time <span className="text-secondary">*</span>
              </label>
              <div className="flex flex-wrap gap-2">
                {times.map((time) => (
                  <button
                    key={time}
                    type="button"
                    onClick={() => handleTimeSelect(time)}
                    className={`px-4 py-2 rounded-md ${formData.time === time
                      ? 'bg-secondary text-white'
                      : 'bg-gray-200 text-black hover:bg-gray-300'
                      }`}
                  >
                    {time}
                  </button>
                ))}
              </div>
            </div>

            <div className="w-full flex items-center justify-end">
              <button
                type="submit"
                className="bg-secondary text-white px-6 py-1.5 text-[14px] font-medium rounded-md"
              >
                Submit Booking
              </button>
            </div>
          </form>
        </Modal>
      )}
      {openModal && (
        <Modal title="Place Order" onClose={handleCloseModal}>
          <h2 className="text-[18px] font-medium mb-4">Express Interest in {selectedProperty?.title}</h2>

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Select Payment Type
            </label>
            <div className="flex gap-4 mt-2">
              <button
                className={`px-6 py-1.5 text-[14px] rounded-md  border font-medium ${paymentType === 'one_time' ? 'bg-secondary text-white' : 'border-gray-300 text-gray-700'
                  }`}
                onClick={() => setPaymentType('one_time')}
              >
                One-Time Payment
              </button>
              <button
                className={`px-6 py-1.5 text-[14px] rounded-md border font-medium ${paymentType === 'installments' ? 'bg-secondary text-white' : 'border-gray-300 text-gray-700'
                  }`}
                onClick={() => setPaymentType('installments')}
              >
                Installments
              </button>
            </div>
          </div>

          {paymentType === 'one_time' && (
            <div className="mb-4">
              <p className="text-sm text-gray-700">You have selected to pay the full amount in one payment.</p>
            </div>
          )}

          {paymentType === 'installments' && (
            <div className="mb-4">
              <p className="text-sm text-gray-700">You have selected to pay in installments.</p>
            </div>
          )}

          <div className="flex justify-end gap-4">
            <button onClick={handleCloseModal} className="text-[14px] font-medium text-secondary">
              Cancel
            </button>
            <button
              onClick={handleSubmitOrder}
              disabled={loading}
              className="bg-secondary text-white px-6 py-1.5 text-[14px] font-medium rounded-md"
            >
              {loading ? 'Processing, please wait...' : 'Submit Order'}
            </button>
          </div>
        </Modal>
      )}
    </Layout>
  )
}

export default ViewProject