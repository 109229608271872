import React, { useEffect, useState } from 'react';
import CustomerKYC from './CustomerKYC';
import { useParams } from 'react-router-dom';
import { fetchCustomerById,  updateCustomer } from '../utils/api/api';
import banner from '../assets/banner.png'
import { MdOutlineAttachment } from 'react-icons/md';
import { CiEdit } from 'react-icons/ci';
import { FaRegUser } from 'react-icons/fa';
import CustomerInfo from './CustomerInfo';
import toast from 'react-hot-toast';
import Loading from '../elements/Loading';
import Layout from '../elements/Layout';
import Modal from '../elements/Modal';
import Cookies from 'js-cookie';

const Account = () => {
    const [activeTab, setActiveTab] = useState('Info');
    const [customer, setCustomer] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const customerId = Cookies.get('user_id');
    const [showEditModal, setShowEditModal] = useState(false);
    const [editCustomerData, setEditCustomerData] = useState({
        id: customer?.id || "",
        email: customer?.email || "",
        first_name: customer?.first_name || "",
        last_name: customer?.last_name || "",
        phone_number: customer?.phone_number || "",
        id_number: customer?.id_number || "",
        dob: customer?.dob || "",
        gender: customer?.gender || "",
        nationality: customer?.nationality || "",
        city: customer?.city || "",
        state: customer?.state || "",
        country: customer?.country || "",
        address: customer?.address || ""
    });

    useEffect(() => {
        if (customer) {
            setEditCustomerData({
                id: customer.id || "",
                email: customer.email || "",
                first_name: customer.first_name || "",
                last_name: customer.last_name || "",
                phone_number: customer.phone_number || "",
                id_number: customer.id_number || "",
                dob: customer.dob || "",
                gender: customer.gender || "",
                nationality: customer.nationality || "",
                city: customer.city || "",
                state: customer.state || "",
                country: customer.country || ""
            });
        }
    }, [customer]);

    const handleEditInputChange = (e) => {
        const { name, value } = e.target;
        setEditCustomerData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    useEffect(() => {
        const getCustomer = async () => {
            try {
                const data = await fetchCustomerById(customerId);
                setCustomer(data.customer);
                setLoading(false);
            } catch (err) {
                setError('Failed to fetch customer.');
                setLoading(false);
            }
        };

        getCustomer();
    }, [customerId]);

    const handleCustomerUpdate = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            const response = await updateCustomer(editCustomerData);
            toast.success("Customer updated successfully");
            setShowEditModal(false);
        } catch (error) {
            const errorMessage = error.message || 'An error occurred.';
            toast.error(errorMessage);
        } finally {
            setLoading(false);
        }
    };

    const renderTabContent = () => {
        switch (activeTab) {
            case 'Info':
                return <CustomerInfo customer={customer} />;
            case 'Documents':
                return <CustomerKYC />;
            default:
                return <CustomerInfo />;
        }
    };

    return (
        <Layout text={"Customer"}>
            {loading && <Loading />}
            <div className="flex w-full h-fit">
                <img src={banner} className='rounded-t-md w-full' alt="" />
            </div>
            <div className="flex md:px-10 gap-4 ">
                <div className="w-[150px] h-[150px] -mt-[45px] md:-mt-[75px] rounded-full">
                    <img className='rounded-full' src="https://imgs.search.brave.com/fbxInw05M6mkNEHaT64Qm3dNEzTIVXrwCD4lxk4ve3A/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly90NC5m/dGNkbi5uZXQvanBn/LzAyLzE3LzM0LzY3/LzM2MF9GXzIxNzM0/Njc4Ml83WHBDVHQ4/YkxOSnF2VkFhRFpK/d3Zaam0wZXBRbWo2/ai5qcGc" alt="" />
                </div>
                <div className="flex items-center w-full justify-between">
                    <p className="text-[22px] font-semibold text-primary -mt-12 md:mt-0">{customer?.first_name} {customer?.last_name}</p>
                    <div className="flex w-fit gap-2 text-black items-center -mt-[120px]">
                        <CiEdit className='mb-[4px] cursor-pointer' onClick={() => setShowEditModal(true)} size={24} />
                    </div>
                </div>
            </div>

            <div className="w-full gap-4 md:mt-4 flex flex-col md:flex-row mb-4">
                <div className="bg-white w-full p-4 rounded-lg md:w-[25%] flex-col md:flex-row">
                    <div className="flex w-full justify-between items-center gap-4 md:hidden">
                        <FaRegUser
                            size={24}
                            className={`${activeTab === 'Info' ? 'text-secondary' : ''}`}
                            onClick={() => setActiveTab('Info')}
                        />
                        <MdOutlineAttachment
                            size={24}
                            className={`${activeTab === 'Documents' ? 'text-secondary' : ''}`}
                            onClick={() => setActiveTab('Documents')}
                        />
                    </div>
                    <div className="w-full rounded-md hidden md:flex flex-col gap-4">
                        <button
                            className={`py-1.5 px-6 text-[14px] font-medium rounded-md  ${activeTab === 'Info' ? 'bg-secondary text-white' : 'bg-gray-200'}`}
                            onClick={() => setActiveTab('Info')}
                        >
                            Customer Info
                        </button>
                        <button
                            className={`py-1.5 px-6 text-[14px] font-medium rounded-md  ${activeTab === 'Documents' ? 'bg-secondary text-white' : 'bg-gray-200'}`}
                            onClick={() => setActiveTab('Documents')}
                        >
                            Documents
                        </button>
                    </div>
                </div>
                <div className="w-full flex md:w-[75%]">
                    {renderTabContent()}
                </div>
            </div>

            {showEditModal && (
                <Modal onClose={() => setShowEditModal(false)} title="Edit Customer">
                    <form onSubmit={handleCustomerUpdate}>
                        <div className="flex w-full items-center gap-4">
                            <div className="mb-4 w-full">
                                <label className="block text-black text-[14px] font-medium mb-1" htmlFor="first_name">
                                    First Name <span className='text-secondary'>*</span>
                                </label>
                                <input
                                    id="first_name"
                                    type="text"
                                    name="first_name"
                                    value={editCustomerData.first_name || ''}
                                    onChange={handleEditInputChange}
                                    className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                                />
                            </div>
                            <div className="mb-4 w-full">
                                <label className="block text-black text-[14px] font-medium mb-1" htmlFor="last_name">
                                    Last Name <span className='text-secondary'>*</span>
                                </label>
                                <input
                                    id="last_name"
                                    type="text"
                                    name="last_name"
                                    value={editCustomerData.last_name || ''}
                                    onChange={handleEditInputChange}
                                    className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                                />
                            </div>
                        </div>
                        <div className="mb-4">
                            <label className="block text-black text-[14px] font-medium mb-1" htmlFor="email">
                                Email <span className='text-secondary'>*</span>
                            </label>
                            <input
                                id="email"
                                type="email"
                                name="email"
                                value={editCustomerData.email || ''}
                                onChange={handleEditInputChange}
                                className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                            />
                        </div>
                        <div className="w-full flex items-center gap-4">
                            <div className="mb-4 w-full">
                                <label className="block text-black text-[14px] font-medium mb-1" htmlFor="phone_number">
                                    Phone Number <span className='text-secondary'>*</span>
                                </label>
                                <input
                                    id="phone_number"
                                    type="text"
                                    name="phone_number"
                                    value={editCustomerData.phone_number || ''}
                                    onChange={handleEditInputChange}
                                    className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                                />
                            </div>
                            <div className="mb-4 w-full">
                                <label className="block text-black text-[14px] font-medium mb-1" htmlFor="id_number">
                                    ID Number <span className='text-secondary'>*</span>
                                </label>
                                <input
                                    id="id_number"
                                    type="text"
                                    name="id_number"
                                    value={editCustomerData.id_number || ''}
                                    onChange={handleEditInputChange}
                                    className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                                />
                            </div>
                        </div>
                        <div className="mb-4">
                            <label className="block text-black text-[14px] font-medium mb-1" htmlFor="dob">
                                Date of Birth <span className='text-secondary'>*</span>
                            </label>
                            <input
                                id="dob"
                                type="date"
                                name="dob"
                                value={editCustomerData.dob || ''}
                                onChange={handleEditInputChange}
                                className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-black text-[14px] font-medium mb-1" htmlFor="gender">
                                Gender <span className='text-secondary'>*</span>
                            </label>
                            <select
                                id="gender"
                                name="gender"
                                value={editCustomerData.gender || ''}
                                onChange={handleEditInputChange}
                                className="border w-full bg-transparent border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                            >
                                <option value="">Select Gender</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>
                        <div className="mb-4">
                            <label className="block text-black text-[14px] font-medium mb-1" htmlFor="nationality">
                                Nationality <span className='text-secondary'>*</span>
                            </label>
                            <input
                                id="nationality"
                                type="text"
                                name="nationality"
                                value={editCustomerData.nationality || ''}
                                onChange={handleEditInputChange}
                                className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-black text-[14px] font-medium mb-1" htmlFor="city">
                                City <span className='text-secondary'>*</span>
                            </label>
                            <input
                                id="city"
                                type="text"
                                name="city"
                                value={editCustomerData.city || ''}
                                onChange={handleEditInputChange}
                                className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-black text-[14px] font-medium mb-1" htmlFor="state">
                                State <span className='text-secondary'>*</span>
                            </label>
                            <input
                                id="state"
                                type="text"
                                name="state"
                                value={editCustomerData.state || ''}
                                onChange={handleEditInputChange}
                                className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-black text-[14px] font-medium mb-1" htmlFor="country">
                                Country <span className='text-secondary'>*</span>
                            </label>
                            <input
                                id="country"
                                type="text"
                                name="country"
                                value={editCustomerData.country || ''}
                                onChange={handleEditInputChange}
                                className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                            />
                        </div>
                        <div className="w-full flex items-center gap-2 justify-end">
                            <button className="text-secondary font-medium text-[14px]" onClick={() => setShowEditModal(false)}>Cancel</button>
                            <button type="submit" className="rounded-md bg-secondary text-white text-[13px] font-medium px-6 py-1.5">
                                Save
                            </button>
                        </div>
                    </form>
                </Modal>
            )}

        </Layout>
    );
};

export default Account;