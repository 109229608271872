import React, { useEffect, useState } from 'react';
import { MdAdd, MdOutlineAttachment, MdDelete, MdRemoveRedEye } from 'react-icons/md'; // Import the eye icon
import Modal from '../elements/Modal';
import { fetchCustomerDocs } from '../utils/api/api'; // import deleteDocument
import toast from 'react-hot-toast';
import Cookies from 'js-cookie';

const CustomerKYC = () => {
    const [documents, setDocuments] = useState([]);
    const [title, setTitle] = useState('');
    const [note, setNote] = useState('');
    const [doc, setDoc] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const id = Cookies.get('user_id');

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setDoc(file);
        }
    };

    const getDocs = async () => {
        try {
            const data = await fetchCustomerDocs(id);
            setDocuments(data.documents);
        } catch (err) {
            toast.error('Failed to get documents.');
        }
    }

    const handleViewDocument = (document) => {
        setSelectedDocument(document); // Set the selected document
        setIsModalOpen(true); // Open modal for viewing
    };

    useEffect(() => {
        getDocs();
    }, [id]);

    return (
        <div className='w-full'>
            <div className="w-full flex items-center justify-between">
                <div className="flex flex-col">
                    <p className="text-secondary text-[16px] font-medium">Documents</p>
                    <p className="text-[14px] text-lead font-light hidden md:flex">Add notes & documents in the system</p>
                </div>
            </div>

            {isModalOpen && selectedDocument ? (
                <Modal title="Document Details" onClose={() => setIsModalOpen(false)}>
                    <div>
                        <h2 className="text-[16px] font-medium">Title: {selectedDocument.title}</h2>
                        <p className="text-[14px] text-gray-600">Note: {selectedDocument.note}</p>
                        <a href={selectedDocument.file} target='_blank' rel="noreferrer" className="text-blue-600 underline">
                            View Attachment
                        </a>
                    </div>
                </Modal>
            ) : isModalOpen && !selectedDocument && (
                <Modal title="Add Document" onClose={() => setIsModalOpen(false)}>
                    <form>
                        <div className="w-full mb-4">
                            <label htmlFor="title" className="block text-black text-[14px] font-medium mb-1">Title</label>
                            <input
                                type="text"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                placeholder="Document Title"
                                className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                            />
                        </div>
                        <div className="w-full mb-4">
                            <label htmlFor="note" className="block text-black text-[14px] font-medium mb-1">Note</label>
                            <textarea
                                value={note}
                                onChange={(e) => setNote(e.target.value)}
                                placeholder="Note"
                                className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                            />
                        </div>
                        <input
                            type="file"
                            onChange={handleFileChange}
                            className="block w-full mb-2"
                        />
                        <div className="flex w-full items-center justify-end mt-4">
                            <button
                                type="submit"
                                disabled={loading}
                                className=" bg-secondary text-white px-4 py-1.5 text-[14px] font-medium rounded-md"
                            >
                                {loading ? 'Please wait' : 'Add Document'}
                            </button>
                        </div>
                    </form>
                </Modal>
            )}

            <div className="w-full bg-white rounded-lg mt-4">
                <div className="overflow-x-auto rounded-lg">
                    <table className="min-w-full bg-white h-full flex-grow rounded-lg border border-gray-200 table-auto">
                        <thead>
                            <tr className="text-primary border-b pb-4 text-[14px] py-3 bg-[#F7F7F7] font-medium leading-normal">
                                <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Title</th>
                                <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Note</th>
                                <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Date</th>
                                <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Attachment</th>
                                <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Actions</th> {/* Actions column */}
                            </tr>
                        </thead>
                        <tbody className="text-gray-700 text-[13px]">
                            {documents.length === 0 ? (
                                <tr>
                                    <td colSpan="7" className="py-4 px-6 text-center text-gray-500">
                                        {loading ? 'Loading, please wait' : 'No data found'}
                                    </td>
                                </tr>
                            ) : (
                                documents.map((document, index) => (
                                    <tr
                                        key={index}
                                        className={`border-b border-gray-200 cursor-pointer hover:bg-gray-50 ${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                                            }`}
                                    >
                                        <td className="py-4 px-6 text-left whitespace-nowrap">
                                            <span>{document.title}</span>
                                        </td>
                                        <td className="py-4 px-6 text-left whitespace-nowrap">
                                            {document.note.split(' ').slice(0, 5).join(' ')}...
                                        </td>
                                        <td className="py-4 px-6 text-left whitespace-nowrap">
                                            <span>{new Date(document.createdAt).toLocaleDateString()}</span>
                                        </td>
                                        <td className="py-4 px-6 text-left whitespace-nowrap">
                                            <a href={document.file} target='_blank'><MdOutlineAttachment size={24} className='' /></a>
                                        </td>
                                        <td className="py-4 px-6 text-left whitespace-nowrap flex gap-2">
                                            <button
                                                onClick={() => handleViewDocument(document)} // Button to view document details
                                                className="text-green-500 hover:text-green-700"
                                            >
                                                <MdRemoveRedEye size={24} />
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default CustomerKYC;