import React from 'react'
import TopMenu from '../components/TopMenu'
import Footer from '../components/Footer'
import SideMenu from '../components/SideMenu'

const Layout = ({ children, text, backgroundColor = 'bg-gray-100' }) => {
    return (
        <div className="flex flex-col h-[100vh] w-full">
            <TopMenu header={text} />
            <div className="flex w-full h-[88vh]">
                <div className="w-[18%] pl-[3%] bg-primary hidden md:flex">
                    <SideMenu />
                </div>
                <div className={`flex flex-col flex-grow overflow-auto w-full md:w-[82%] py-8 px-[5%] md:pl-8 md:pr-[3%] h-auto ${backgroundColor}`}>
                    {children}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Layout
