import React, { useEffect, useState } from 'react';
import Layout from '../elements/Layout';
import { IoMdSearch } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { fetchCustomerInvoices } from '../utils/api/api';
import toast from 'react-hot-toast';
import Cookies from 'js-cookie';
import Loading from '../elements/Loading';
import moment from 'moment';

const Invoices = () => {
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const customer_id = Cookies.get('user_id');

  const getInvoices = async () => {
    setLoading(true);
    try {
      const response = await fetchCustomerInvoices(customer_id);
      setInvoices(response.invoices);
    } catch (error) {
      toast.error("Failed to get invoices");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getInvoices();
  }, []);

  const formatInvoiceNumber = (invoiceNumber) => {
    const yearSuffix = moment().format('YY');
    return `MVL-${invoiceNumber}-${yearSuffix}`;
  };

  const filteredInvoices = invoices.filter(invoice =>
    invoice.payment_status === 'paid' || invoice.payment_status === 'partially_paid'
  );
  const upcomingInvoice = invoices.reduce((closest, invoice) => {
    const dueDate = new Date(invoice.payment_due_date);
    if ((invoice.payment_status === 'pending' || invoice.payment_status === 'partially paid') &&
      dueDate > new Date() &&
      (!closest || dueDate < new Date(closest.payment_due_date))) {
      return invoice;
    }
    return closest;
  }, null);

  return (
    <Layout text="Invoices" backgroundColor="bg-white">
      {loading && <Loading />}
      <div className="flex w-full justify-between items-center mb-6">
        <div className="w-fit flex items-center gap-2 px-4 py-1.5 rounded-full bg-gray-100 border border-gray-300">
          <IoMdSearch className="text-gray-500" />
          <input
            type="text"
            placeholder="Search"
            className="outline-none border-none text-gray-600 font-light text-[14px] bg-transparent"
          />
        </div>
      </div>

      <div className="w-full bg-white rounded-lg shadow-md">
        <div className="overflow-x-auto rounded-lg">
          <table className="min-w-full bg-white h-full rounded-lg border border-gray-200 table-auto">
            <thead>
              <tr className="text-gray-600 border-b pb-4 text-[14px] py-3 bg-[#F7F7F7] font-medium">
                <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Invoice No.</th>
                <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Title Number</th>
                <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Serial No.</th>
                <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Payment Status</th>
                <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Due date</th>
                <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Amount</th>
              </tr>
            </thead>
            <tbody>
              {filteredInvoices.length > 0 ? (
                filteredInvoices.map((invoice, index) => (
                  <tr
                    key={invoice.invoiceNumber}
                    onClick={() => navigate(`/dashboard/invoices/${invoice.id}/view`)}
                    className={`text-[14px] text-gray-700 border-b border-gray-200 cursor-pointer hover:bg-gray-50 ${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}`}
                  >
                    <td className="py-4 px-6 text-left whitespace-nowrap">
                      {formatInvoiceNumber(invoice.id)}
                    </td>
                    <td className="py-4 px-6 text-left whitespace-nowrap">
                      {invoice.order.property.title_number}
                    </td>
                    <td className="py-4 px-6 text-left whitespace-nowrap">
                      {invoice.serial_no}
                    </td>
                    <td className="py-4 px-6 text-left whitespace-nowrap">
                      {new Date(invoice.payment_due_date) < new Date() && invoice.payment_status !== 'paid' ? (
                        <span className="px-2 py-1 rounded-md text-[12px] bg-red-100 text-red-700">
                          Overdue
                        </span>
                      ) : (
                        <span className={`px-2 py-1 rounded-md text-[12px] ${invoice.payment_status === 'paid' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
                          {invoice.payment_status}
                        </span>
                      )}
                    </td>
                    <td className="py-4 px-6 text-left whitespace-nowrap">
                      {moment(invoice.payment_due_date).format('MMM DD, YYYY')}
                    </td>
                    <td className="py-4 px-6 text-left whitespace-nowrap">
                      KES {Number(invoice.total_amount).toLocaleString()}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="py-4 px-6 text-center text-gray-600 text-[14px]">
                    No paid invoices found.
                  </td>
                </tr>
              )}

              {upcomingInvoice && (
                <tr
                  key={upcomingInvoice.invoiceNumber}
                  onClick={() => navigate(`/dashboard/invoices/${upcomingInvoice.id}/view`)}
                  className="text-[14px] text-gray-700 border-b border-gray-200 cursor-pointer hover:bg-gray-50 bg-gray-50"
                >
                  <td className="py-4 px-6 text-left whitespace-nowrap">
                    {formatInvoiceNumber(upcomingInvoice.id)}
                  </td>
                  <td className="py-4 px-6 text-left whitespace-nowrap">
                    {upcomingInvoice.order.property.title_number}
                  </td>
                  <td className="py-4 px-6 text-left whitespace-nowrap">
                    {upcomingInvoice.serial_no}
                  </td>
                  <td className="py-4 px-6 text-left whitespace-nowrap">
                    <span
                      className={`px-2 py-1 rounded-md text-[12px] ${upcomingInvoice.payment_status === 'paid'
                          ? 'bg-green-100 text-green-700'
                          : upcomingInvoice.payment_status === 'pending'
                            ? 'bg-yellow-100 text-yellow-700'
                            : upcomingInvoice.payment_status === 'partially paid'
                              ? 'bg-orange-100 text-orange-700'
                              : 'bg-red-100 text-red-700' 
                        }`}
                    >
                      {upcomingInvoice.payment_status}
                    </span>
                  </td>
                  <td className="py-4 px-6 text-left whitespace-nowrap">
                    {moment(upcomingInvoice.payment_due_date).format('MMM DD, YYYY')}
                  </td>
                  <td className="py-4 px-6 text-left whitespace-nowrap">
                    KES {Number(upcomingInvoice.total_amount).toLocaleString()}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  );
};

export default Invoices;
