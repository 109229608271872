import React from 'react';
import Loading from '../elements/Loading';

const CustomerInfo = ({ customer }) => {
    if (!customer) {
        return <Loading />;
    }

    return (
        <div className='flex flex-col gap-4 w-full'>
            {/* Personal Details Section */}
            <div className="p-6 flex flex-col w-full rounded-md border border-gray-300 bg-white shadow-md">
                <p className="text-xl text-gray-800 font-semibold mb-4">Personal Details</p>
                <div className="flex flex-wrap md:flex-row w-full gap-6">
                    <DetailItem label="Name" value={`${customer.first_name} ${customer.last_name}`} />
                    <DetailItem label="Gender" value={customer.gender || 'N/A'} />
                    <DetailItem label="D.O.B" value={customer.dob || 'N/A'} />
                    <DetailItem label="ID No." value={customer.id_number || 'N/A'} />
                </div>
            </div>

            {/* Address Section */}
            <div className="p-6 flex flex-col w-full rounded-md border border-gray-300 bg-white shadow-md">
                <p className="text-xl text-gray-800 font-semibold mb-4">Address</p>
                <div className="flex flex-wrap md:flex-row w-full gap-6">
                    <DetailItem label="City" value={customer.city || 'N/A'} />
                    <DetailItem label="Nationality" value={customer.nationality || 'N/A'} />
                    <DetailItem label="State" value={customer.state || 'N/A'} />
                    <DetailItem label="Country" value={customer.country || 'N/A'} />
                </div>
            </div>

            {/* Contact Details Section */}
            <div className="p-6 flex flex-col w-full rounded-md border border-gray-300 bg-white shadow-md">
                <p className="text-xl text-gray-800 font-semibold mb-4">Contact Details</p>
                <div className="flex flex-col md:flex-row w-full justify-start gap-16">
                    <DetailItem label="Phone Number" value={customer.phone_number} />
                    <DetailItem label="Email" value={customer.email || 'N/A'} />
                </div>
            </div>
        </div>
    );
};

// Helper component for displaying individual detail items
const DetailItem = ({ label, value }) => (
    <div className="flex flex-col">
        <p className='text-base text-gray-600'>{label}</p>
        <p className='text-lg text-gray-800 font-medium'>{value}</p>
    </div>
);

export default CustomerInfo;
