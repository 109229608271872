import React, { useEffect, useState } from 'react';
import Layout from '../elements/Layout';
import Cookies from 'js-cookie';
import { fetchCustomerOrders } from '../utils/api/api';
import toast from 'react-hot-toast';
import { FaEye } from "react-icons/fa6";
import Loading from '../elements/Loading';
import { IoMdSearch } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const customer_id = Cookies.get('user_id');
  const navigate = useNavigate();

  const getOrders = async () => {
    setLoading(true);
    try {
      const response = await fetchCustomerOrders(customer_id);
      setOrders(response.orders);
    } catch (error) {
      toast.error("Request failed");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getOrders();
  }, []);

  const handleMakePayment = (orderId) => {
    navigate(`/dashboard/orders/${orderId}/view`)
  };

  return (
    <Layout text="Orders">
      {loading ? (
        <Loading />
      ) : (
        <div className="w-full bg-white rounded-lg p-2">
          <div className="flex items-center justify-between w-full mb-2">
            <div className="w-fit flex items-center gap-2 px-4 py-1.5 rounded-md bg-[#F7F7F7] border border-gray-200">
              <IoMdSearch className='text-gray-600' />
              <input
                type="text"
                placeholder='Search'
                className='outline-none border-none text-gray-600 bg-transparent font-light text-[14px]'
              />
            </div>
            {/* <button 
              onClick={() => navigate('/dashboard/orders/pay')}
              className="bg-secondary px-6 py-1.5 text-white text-[14px] font-medium rounded-md"
            >
                Pay
            </button> */}
          </div>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white table-auto">
              <thead>
                <tr className="bg-gray-100 text-gray-700 uppercase text-sm leading-normal">
                  <th className="py-3 px-6 text-left">Property number</th>
                  <th className="py-3 px-6 text-left">Title number</th>
                  <th className="py-3 px-6 text-left">Amount (Kes)</th>
                  <th className="py-3 px-6 text-left">Payment Status</th>
                  <th className="py-3 px-6 text-left">Created At</th>
                  <th className="py-3 px-6 text-left">Action</th>
                </tr>
              </thead>
              <tbody className="text-gray-600 text-[14px]">
                {orders.length > 0 ? (
                  orders.map((order) => (
                    <tr key={order.id} className="border-b border-gray-200 hover:bg-gray-50">
                      <td className="py-3 px-6 text-left whitespace-nowrap">
                        {order?.property?.property_number}
                      </td>
                      <td className="py-3 px-6 text-left whitespace-nowrap">
                        {order?.property?.title_number}
                      </td>
                      <td className="py-3 px-6 text-left whitespace-nowrap">
                        {Number(order.amount).toLocaleString("KES")}
                      </td>
                      <td className="py-3 px-6 text-left whitespace-nowrap">
                        {order.payment_status}
                      </td>
                      <td className="py-3 px-6 text-left whitespace-nowrap">
                        {new Date(order.createdAt).toLocaleDateString()}
                      </td>
                      <td className="py-3 px-6 text-left whitespace-nowrap">
                        {order.payment_status !== 'paid' ? (
                          <button
                            onClick={() => handleMakePayment(order.id)}
                            className="px-3 py-1 rounded-full text-white bg-secondary text-[13px]"
                          >
                            Pay
                          </button>
                        ) : (
                          <button
                            onClick={() => handleMakePayment(order.id)}
                            className=" flex items-center gap-2 text-gray-400 text-[15px] capitalize "
                          >
                            {order.payment_status} 
                            <FaEye 
                              className="text-secondary "
                              size={24}
                            />
                          </button>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" className="py-4 text-center">
                      No orders found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default Orders;
