import React, { useEffect, useState } from 'react'
import Layout from '../elements/Layout'
import { IoMdSearch } from 'react-icons/io'
import moment from 'moment';
import { FaRegEye } from "react-icons/fa6";
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { fetchCustomerContracts } from '../utils/api/api';
import toast from 'react-hot-toast';
import Loading from '../elements/Loading';

const Contracts = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const id = Cookies.get('user_id');
  const [contracts, setContracts] = useState([]);

  const getContracts = async () => {
    setLoading(true);
    try {
      const response = await fetchCustomerContracts(id);
      setContracts(response.contracts);
    } catch (error) {
      toast.error("Failed to get contracts");
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getContracts();
  }, [id])

  const getStatusStyle = (status) => {
    switch (status.toLowerCase()) {
      case 'approved':
        return 'bg-green-100 text-green-600';
      case 'pending':
        return 'bg-yellow-100 text-yellow-600';
      case 'cancelled':
        return 'bg-red-100 text-red-600';
      default:
        return 'bg-gray-100 text-gray-600';
    }
  };
  
  return (
    <Layout backgroundColor='text-white' text="Contracts">
      {loading && <Loading />}
      <div className="flex w-full justify-between items-center mb-4">
        <div className="w-fit flex items-center gap-2 px-4 py-1.5 rounded-md bg-[#F7F7F7] border border-gray-200">
          <IoMdSearch className='text-gray-600' />
          <input
            type="text"
            placeholder='Search'
            className='outline-none bg-transparent border-none text-gray-600 font-light text-[14px]'
          />
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {contracts.map((contract, index) => (
          <div key={contract.id} className="p-6 border rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
            <div className="flex items-center justify-between">
              <h3 className="text-lg font-semibold text-gray-700">{contract.title}</h3>
              <FaRegEye
                size={20}
                className="text-secondary cursor-pointer"
                onClick={() => navigate(`/dashboard/contracts/${contract.id}/view-details`)}
              />
            </div>
            <p className="text-sm text-gray-500 capitalize">{contract.type.replace('_', ' ')}</p>
            <div className="my-4">
              <p className="text-sm"><strong>Starts:</strong> {moment(contract.start_date).format('MMM Do YYYY')}</p>
              <p className="text-sm"><strong>Ends:</strong> {moment(contract.end_date).format('MMM Do YYYY')}</p>
            </div>
            <div className="flex justify-between items-center">
              <span className={`px-3 py-1 rounded-full text-sm font-medium ${getStatusStyle(contract.status)}`}>
                {contract.status}
              </span>
              <p className="text-sm text-gray-500">Received: {moment(contract.createdAt).format('MMM Do YYYY')}</p>
            </div>
          </div>
        ))}
      </div>

    </Layout >
  )
}

export default Contracts