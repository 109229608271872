import React, { useContext, useEffect, useState } from 'react';
import Layout from '../elements/Layout';
import { IoMdSearch } from "react-icons/io";
import { fetchCustomerStats } from '../utils/api/api';
import toast from 'react-hot-toast';
import Loading from '../elements/Loading';
import Cookies from 'js-cookie';
import { AuthContext } from '../utils/context/AuthContext';
import { Link } from 'react-router-dom';
import WalletBalance from '../minicomponents/WalletBalance';

const Dashboard = () => {
  const [stats, setStats] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useContext(AuthContext);
  const customer_id = Cookies.get('user_id');

  const getStats = async () => {
    setLoading(true)
    try {
      const response = await fetchCustomerStats(customer_id);
      setStats(response.data);
    } catch (error) {
      toast.error("Failed to get stats")
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getStats()
  }, [])

  return (
    <Layout text="Dashboard" backgroundColor='bg-[#FCFCFC]'>
      {loading && <Loading />}
      <div className="flex items-center justify-between w-full">
        <p className="">Hello, {user?.fist_name}</p>
        <div className="w-fit flex items-center gap-2 px-4 py-1.5 rounded-full bg-white border border-gray-200">
          <IoMdSearch className='text-gray-600' />
          <input
            type="text"
            placeholder='Search'
            className='outline-none border-none text-gray-600 font-light text-[14px]'
          />
        </div>
      </div>

      <div className='mt-4 w-full grid grid-cols-2 md:grid-cols-4 gap-4'>
        <div className="bg-white w-full rounded-lg p-4 flex flex-col gap-1.5  border border-gray-100">
          <p className="text-primary text-[13px]">Properties listed</p>
          <hr />
          <p className="text-primary text-[16px]">{stats?.properties}</p>
        </div>
        <div className="bg-white w-full rounded-lg p-4 flex flex-col gap-1.5  border border-gray-100">
          <p className="text-primary text-[13px]">Your purchases</p>
          <hr />
          <p className="text-primary text-[16px]">{stats.my_properties}</p>
        </div>
        <div className="bg-white w-full rounded-lg p-4 flex flex-col gap-1.5  border border-gray-100">
          <p className="text-primary text-[13px]">In progress</p>
          <hr />
          <p className="text-primary text-[16px]">{stats.purchases_inProgress}</p>
        </div>
        <div className="bg-white w-full rounded-lg p-4 flex flex-col gap-1.5  border border-gray-100">
          <p className="text-primary text-[13px]">Contracts</p>
          <hr />
          <p className="text-primary text-[16px]">{stats.contracts}</p>
        </div>
      </div>

      <div className="flex flex-col-reverse md:flex-row gap-4 mt-4 w-full">
        <div className="flex flex-col w-full md:w-[65%]">
          <h2 className="text-xl font-semibold mb-2">Popular listings</h2>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
            {stats?.popular_listing?.map((property) => (
              <Link to={`/dashboard/projects/${property.id}/view`} key={property.id} className="bg-white rounded-lg p-4 hover:shadow-md cursor-pointer shadow-sm border border-gray-100">
                <img
                  src={property?.images?.file || 'https://imgs.search.brave.com/L0mOyBBDOxVD6jmTiwNh93bAa_8D_KweRLE_oYnq-Kg/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly9jZG4u/dmVjdG9yc3RvY2su/Y29tL2kvcHJldmll/dy0xeC82MS80Ni9n/YWxsZXJ5LWljb24t/c2lsaG91ZXR0ZS1v/Zi1hbi1pbWFnZS1w/aG90by1hbGJ1bS12/ZWN0b3ItNDU0MzYx/NDYuanBn'}
                  alt={property.title}
                  className="w-full h-40 object-cover rounded-lg mb-4"
                />
                <h3 className="text-[18px] text-primary font-medium truncate">{property.title} - {property.location}</h3>
                <p className="text-[14px] text-gray-600">KES {Number(property.price).toLocaleString("KES")}</p>
                <p className="text-[14px] text-secondary font-medium">View</p>
              </Link>
            ))}
          </div>
        </div>

        <WalletBalance />
      </div>
    </Layout>
  );
}

export default Dashboard;
