import React, { useEffect, useState } from 'react';
import Layout from '../elements/Layout';
import { fetchOrder } from '../utils/api/api';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../elements/Loading';
import logo from '../assets/miles_logo.svg';
import moment from 'moment';
import { FaAngleDown, FaAngleUp, FaTimes, FaTimesCircle } from 'react-icons/fa';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Modal from '../elements/Modal';
import { FaDownload } from 'react-icons/fa6';

const PropertyOrder = () => {
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isInstallmentOpen, setIsInstallmentOpen] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const mastercardLogo = 'https://imgs.search.brave.com/P3S-4nzSLPOsVBu50WqTJkvji1ahb56zJ-22cbFeeHI/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly9hc3Nl/dHMuc3RpY2twbmcu/Y29tL2ltYWdlcy81/ODQ4MjM1NGNlZjEw/MTRjMGI1ZTQ5YzAu/cG5n';
  const visaLogo = 'https://imgs.search.brave.com/bNsCAqw-xAbHOBLJ8nQRkVsPfIQJGxTG2tnzaQY6ytk/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly9zZWVr/bG9nby5jb20vaW1h/Z2VzL1YvVklTQS1s/b2dvLTE2RjRGODJE/MTMtc2Vla2xvZ28u/Y29tLnBuZw';
  const mpesaLogo = 'https://imgs.search.brave.com/ksKqVibKAMDeEPrAJiYsygtSjr-is_IBO44A08W82Ts/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly91cGxv/YWQud2lraW1lZGlh/Lm9yZy93aWtpcGVk/aWEvY29tbW9ucy8w/LzBiL00tUEVTQS5w/bmc';
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleDownloadPDF = () => {
    const doc = new jsPDF();
    doc.text('Installment Report', 10, 10);

    const tableColumn = ["Installment", "Type", "Due Date", "Amount", "Payment Status", "Amount Paid", "Remaining Balance", "Invoice Serial"];
    const tableRows = [];

    order?.installments?.forEach((installment, index) => {
      const installmentData = [
        index + 1,
        installment.type,
        moment(installment.due_date).format('DD MMM, YYYY'),
        `Kes. ${Number(installment.amount).toLocaleString("KES")}`,
        installment.invoice.payment_status,
        `Kes. ${installment.invoice.paid_amount || '0.00'}`,
        `Kes. ${installment.invoice.remaining_balance || 'N/A'}`,
        installment.invoice.serial_no
      ];
      tableRows.push(installmentData);
    });

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 20,
      margin: { top: 10 },
      theme: 'striped',
      styles: {
        fontSize: 10, 
      },
      pageBreak: 'auto',
    });

    doc.save('installments_report.pdf');
  };

  const getOrder = async () => {
    setLoading(true);
    try {
      const response = await fetchOrder(id);
      setOrder(response.order);
    } catch (error) {
      toast.error('Failed to get order');
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getOrder();
  }, [id]);

  if (loading || !order) {
    return <Loading />;
  }

  const { property, payment_type, amount, payment_status, customer } = order;
  const isInstallment = payment_type === 'installments';

  return (
    <Layout text="View Order">
      <div className="max-w-4xl mx-auto p-8 bg-white shadow-lg rounded-lg">
        <div className="flex justify-between items-center mb-6">
          <div className="flex flex-col test-start items-start justify-start">
            <img
              src={logo}
              alt="Company Logo"
              className="h-12 w-auto"
            />
            <p className="text-[18px] font-semibold text-black">Milescoop ventures ltd</p>
          </div>
          <h2 className="text-2xl font-semibold text-gray-800">Order #{id}</h2>
        </div>

        <hr className="mb-6 border-t-2 border-gray-200" />

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-8">
          <div>
            <h3 className="text-xl font-medium text-gray-700">Property Details</h3>
            <div className="">
              <h4 className="text-lg font-semibold text-gray-800">Title: {property.title_number}</h4>
              <p className="text-gray-600">P. No: {property.property_number}</p>
            </div>
          </div>

          <div>
            <h3 className="text-xl font-medium text-gray-700">Customer Information</h3>
            <div className="mt-4">
              <p className="text-sm text-gray-600">
                <strong>Name:</strong> {customer.first_name} {customer.last_name}
              </p>
              <p className="text-sm text-gray-600">
                <strong>Customer Id:</strong> {customer.unique_number}
              </p>
              <p className="text-sm text-gray-600">
                <strong>Email:</strong> {customer.email}
              </p>
            </div>
          </div>
        </div>

        <hr className="mb-6 border-t-2 border-gray-200" />

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-8">
          <div>
            <h3 className="text-xl font-medium text-gray-700">Payment Information</h3>
            <div className="mt-4">
              <p className="text-gray-700">
                <strong>Payment Type:</strong> {payment_type === 'one_time' ? 'One-Time Payment' : 'Installment Plan'}
              </p>
              {order?.payment_status === 'paid' ? (
                <p className="text-gray-600 text-sm mt-1">This property has been fully paid, this grants you a ready title and ownership of the property listed above</p>
              ) : (
                  <p className="text-gray-600 text-sm mt-1">
                    {payment_type === 'one_time'
                      ? 'You have chosen to pay the full amount at once, which grants you immediate ownership of the property upon completion of the transaction.'
                      : `You will be making monthly payments for this property. Once the full amount is paid, you will be handed full ownership of the property.`}
                  </p>
              )}
            </div>
          </div>

          <div>
            <h3 className="text-xl font-medium text-gray-700">Order Summary</h3>
            <div className="mt-4">
              <div className="flex justify-between text-sm">
                <p className="text-gray-600">Property Price:</p>
                <p className="font-semibold text-gray-900">Kes {Number(order.amount).toLocaleString("KES")}</p>
              </div>
              {isInstallment && (
                <>
                </>
              )}
              <div className="flex justify-between text-sm mt-2">
                <p className="text-gray-600">Order Status:</p>
                <p className={`font-semibold uppercase ${payment_status === 'paid' ? 'text-green-600 bg-green-100 px-4 rounded-md' : 'text-red-500'}`}>
                  {payment_status}
                </p>
              </div>
            </div>
          </div>
        </div>

        {isInstallment && (
          <div className="-mt-4">
            <div onClick={() => setIsInstallmentOpen(!isInstallmentOpen)} className="flex bg-gray-50 cursor-pointer w-full items-center justify-between">
              <button
                onClick={() => setIsInstallmentOpen(!isInstallmentOpen)}
                className="text-black font-medium text-[18px]"
              >
                {isInstallmentOpen ? 'Hide Installments' : 'Show Installments'}
              </button>
              <div className="">
                {isInstallmentOpen ? <FaAngleUp /> : <FaAngleDown />}
              </div>
            </div>
            {isInstallmentOpen && (
              <>
                <div className="mt-4 border border-gray-200 rounded-md">
                  {order?.installments
                    ?.sort((a, b) => a.id - b.id)
                    .map((installment, index) => (
                      <div
                        key={installment.id}
                        className={`p-2 flex items-center gap-2 justify-between w-full rounded-md ${index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}`}
                      >
                        <div className="flex items-center gap-2">
                          <p className={`flex items-center justify-center rounded-full w-[40px] h-[40px] text-white ${installment?.invoice?.payment_status === 'paid'
                            ? 'bg-green-600'
                            : installment?.invoice?.payment_status === 'pending'
                              ? 'bg-primary'
                              : installment?.invoice?.payment_status === 'partially_paid'
                                ? 'bg-secondary'
                                : 'bg-red-600'
                            } `}>{index + 1}</p>
                          <div className="flex flex-col">
                            <p className="capitalize text-[14px] font-medium">{installment.type}</p>
                            <p className="font-medium text-black text-[13px] ">Kes. {Number(installment.amount).toLocaleString("KES")}</p>
                          </div>
                        </div>
                        <div className="flex text-end flex-col">
                          <p className="text-[13px] text-gray-600">{moment(installment.due_date).format('DD MMM, YYYY')}</p>
                          <p
                            className={`capitalize text-[13px] font-light ${installment.payment_status === 'complete'
                              ? 'text-green-600'
                              : installment.payment_status === 'pending'
                                ? 'text-secondary'
                                : 'text-red-600'
                              }`}
                          >
                            {installment.payment_status}
                          </p>
                        </div>
                      </div>
                    ))}
                </div>
                <button
                  className="text-blue-600 underline"
                  onClick={() => setIsModalOpen(true)}
                >
                  View Details
                </button>
              </>
            )}
          </div>
        )}

        {isModalOpen && (
          <Modal title="Installment report" onClose={() => setIsModalOpen(false)}>
            <div className="">
              <div className="space-y-6">
                {order?.installments?.map((installment, index) => (
                  <div key={installment.id} className="p-4 bg-gray-50 border rounded-lg shadow-sm hover:shadow-md transition">
                    <div className="flex justify-between items-center mb-3">
                      <h4 className="text-lg font-medium text-gray-700">Installment {index + 1}</h4>
                      <div className={`text-xs font-semibold px-2 py-1 rounded-md ${installment.invoice.payment_status === 'paid' ? 'bg-green-100 text-green-600' : installment.invoice.payment_status === 'pending' ? 'bg-yellow-100 text-yellow-600' : 'bg-red-100 text-red-600'}`}>
                        {installment.invoice.payment_status}
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                      <p><strong>Type:</strong> {installment.type}</p>
                      <p><strong>Due Date:</strong> {moment(installment.due_date).format('DD MMM, YYYY')}</p>
                      <p><strong>Amount:</strong> Kes. {Number(installment.amount).toLocaleString("KES")}</p>
                      <p><strong>Amount Paid:</strong> Kes. {installment.invoice.paid_amount || '0.00'}</p>
                      <p><strong>Remaining Balance:</strong> Kes. {installment.invoice.remaining_balance || 'N/A'}</p>
                      <p><strong>Invoice Serial:</strong> {installment.invoice.serial_no}</p>
                    </div>
                    <div className="mt-4">
                      <div className="text-sm text-gray-500">Progress</div>
                      <div className="relative w-full h-2 bg-gray-200 rounded-md overflow-hidden">
                        <div
                          className="absolute top-0 left-0 h-full bg-blue-500"
                          style={{ width: `${(installment.invoice.paid_amount / installment.amount) * 100 || 0}%` }}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="flex justify-between mt-6">
                <button
                  className="flex items-center gap-2 text-blue-600 font-semibold hover:underline transition"
                  onClick={handleDownloadPDF}
                >
                  <FaDownload /> Download as PDF
                </button>
                <button
                  className="flex items-center gap-2 text-red-600 font-semibold hover:underline transition"
                  onClick={() => setIsModalOpen(false)}
                >
                  <FaTimesCircle /> Close
                </button>
              </div>
            </div>
          </Modal>
        )}

        <hr className="mb-6 mt-4 border-t-2 border-gray-200" />

        {order?.payment_status !== 'paid' && (
        <>
            <div className="mb-8">
              <h3 className="text-xl font-medium text-gray-700">Select Payment Method</h3>
              <p className="text-gray-600 text-sm mb-3">Choose your preferred payment method:</p>
              <div className="flex space-x-6">
                <img
                  src={mpesaLogo}
                  alt="MPesa"
                  className="cursor-pointer h-12 w-auto"
                  onClick={() => navigate(`/dashboard/orders/${id}/pay`)}
                />
                <img
                  src={visaLogo}
                  alt="Visa"
                  className="cursor-pointer h-10 w-auto border border-gray-200 rounded-md"
                  onClick={() => navigate(`/dashboard/orders/${id}/pay`)}
                />
                <img
                  src={mastercardLogo}
                  alt="Mastercard"
                  className="cursor-pointer h-12 w-auto"
                  onClick={() => navigate(`/dashboard/orders/${id}/pay`)}
                />
              </div>
            </div>
            <div className="flex items-center justify-end gap-4 ">
              <button
                className="text-secondary text-[15px] font-medium"
                onClick={() => navigate(-1)}
              >
                Cancel
              </button>
              <button
                className="w-fit py-1.5 px-6 bg-secondary text-white font-medium rounded-md text-[14px] "
                onClick={() => navigate(`/dashboard/orders/${id}/pay`)}
              >
                Confirm & Pay
              </button>
            </div>
        </>
        )}
      </div>
    </Layout>
  );
};

export default PropertyOrder;
