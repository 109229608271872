import React, { useContext, useEffect, useState, useRef } from 'react';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa6';
import Cookies from 'js-cookie';
import moment from 'moment';
import html2pdf from 'html2pdf.js';
import { fetchCustomerProperties, fetchCustomerWallet } from '../utils/api/api';
import toast from 'react-hot-toast';
import Layout from '../elements/Layout';
import seal from '../assets/MILESCOOP_SEAL.webp'
import logo from '../assets/miles_logo.svg';
import sign from '../assets/General_Signature_4.png';
import { AuthContext } from '../utils/context/AuthContext';
import Loading from '../elements/Loading';

const WalletStatement = () => {
    const [wallet, setWallet] = useState(null);
    const [loading, setLoading] = useState(true);
    const { user } = useContext(AuthContext);
    const [properties, setProperties] = useState([]);
    const customer_id = Cookies.get('user_id');
    const statementRef = useRef(null);

    const getProperties = async () => {
        try {
            const response = await fetchCustomerProperties(customer_id);
            setProperties(response.properties);
        } catch (error) {
            toast.error("Failed to get properties");
        }
    };

    const getWallet = async () => {
        try {
            const response = await fetchCustomerWallet(customer_id);
            setWallet(response.wallet);
            setLoading(false);
        } catch (error) {
            toast.error(error.message || "Request failed");
            setLoading(false);
        }
    };

    useEffect(() => {
        getWallet();
        getProperties();
    }, []);

    const formatCurrency = (amount) => {
        return new Intl.NumberFormat('en-KE', {
            style: 'currency',
            currency: 'KES',
        }).format(amount);
    };

    const formatDate = (date) => moment(date).format('MMM DD, YYYY');

    const generatePDF = () => {
        const element = statementRef.current;
        const options = {
            margin: 0.5,
            filename: `wallet_statement_${user?.first_name}_${user?.last_name}.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
        };
        html2pdf().from(element).set(options).save();
    };

    if (loading) return <Loading />;

    return (
        <Layout>
            <div className="mx-auto p-12 bg-white flex flex-col" ref={statementRef}>
                <div className="flex items-center justify-between">
                    <img src={logo} className="w-[150px] mb-6" alt="Logo" />
                    <div className="text-right space-y-1 text-gray-600">
                        <p className="text-[20px] font-semibold text-primary">Milescoop Ventures Ltd</p>
                        <p>P.O. Box 2760 - 00100, Nairobi, Kenya</p>
                        <p>+254 (0) 727 900 424</p>
                        <p>info@milescoop.com</p>
                        <p>www.milescoop.com</p>
                    </div>
                </div>

                <h1 className="my-6 text-2xl font-bold text-gray-700">Full Wallet Statement</h1>

                <div className="flex justify-between mb-6">
                    <div className="text-gray-700">
                        <p className="font-semibold">{user?.first_name} {user?.last_name}</p>
                        <p>{user?.phone_number}</p>
                        <p>{user?.email}</p>
                        <p>{user?.id_number}</p>
                        <p>{user?.state}</p>
                    </div>

                    <div className="text-gray-600">
                        <p className="font-semibold">Properties</p>
                        {properties.length ? (
                            <div className="space-y-2">
                                {properties.map((property, index) => (
                                    <div key={index} className="bg-gray-50 p-3 rounded-lg shadow-sm">
                                        <p className="text-sm">{property.property?.title_number}</p>
                                        <p className="text-sm">{property?.property?.property_number} - {property?.property?.size}</p>
                                        <p className="text-sm">{formatCurrency(property.amount)}</p>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <p className="text-gray-400">No properties available</p>
                        )}
                    </div>
                </div>

                <div className="mb-6 p-6 bg-gradient-to-r from-gray-100 to-gray-300 rounded-lg shadow-lg relative">
                    <div className="absolute top-2 right-4 bg-gradient-to-r from-green-400 to-green-500 rounded-full px-4 py-1 text-white text-sm font-semibold">
                        Active Balance
                    </div>
                    <h2 className="text-lg font-medium text-gray-700">Wallet Balance</h2>

                    <div className="mt-4 flex items-center justify-between bg-white p-4 rounded-lg shadow-inner border-t-4 border-green-400">
                        <p className="text-xl font-bold text-gray-900">Current Balance</p>
                        <p className="text-4xl font-extrabold text-green-500 tracking-wider">
                            {formatCurrency(wallet.balance)}
                        </p>
                    </div>
                </div>
                <div>
                    <h2 className="text-xl font-medium text-gray-700 mb-4">Generated on: {formatDate(new Date())}</h2>
                    <table className="w-full table-auto border-collapse mt-8">
                        <thead>
                            <tr className="bg-gray-100">
                                <th className="p-4 text-left text-sm font-semibold text-gray-600">#</th>
                                <th className="p-4 text-left text-sm font-semibold text-gray-600">Date</th>
                                <th className="p-4 text-left text-sm font-semibold text-gray-600">Property</th>
                                <th className="p-4 text-left text-sm font-semibold text-gray-600">Type</th>
                                <th className="p-4 text-left text-sm font-semibold text-gray-600">Invoice #</th>
                                <th className="p-4 text-right text-sm font-semibold text-gray-600">Amount (KES)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {wallet.transactions.map((transaction, index) => (
                                <tr key={transaction.id} className={`border-t ${index % 2 === 0 ? 'bg-gray-50' : ''}`}>
                                    <td className="p-4">{index + 1}</td>
                                    <td className="p-4">{formatDate(transaction.createdAt)}</td>
                                    <td className="p-4">
                                        {transaction.type === 'debit' && transaction.invoice && transaction.invoice.order ? (
                                            transaction.invoice.order.property.title_number
                                        ) : (
                                            '--'
                                        )}
                                    </td>
                                    <td className="p-4">
                                        {transaction.type === 'credit' ? (
                                            <span className="text-green-600 flex items-center">
                                                <FaArrowDown className="mr-2" />
                                                Credit
                                            </span>
                                        ) : (
                                            <span className="text-red-600 flex items-center">
                                                <FaArrowUp className="mr-2" />
                                                Debit
                                            </span>
                                        )}
                                    </td>
                                    <td className="p-4">{transaction.invoice_id ? `#${transaction.invoice_id}` : '--'}</td>
                                    <td className={`p-4 text-right ${transaction.type === 'credit' ? 'text-green-600' : 'text-red-600'}`}>
                                        {formatCurrency(transaction.amount)}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div className="mt-12">
                    <div className="flex items-center justify-between">
                        <div className="flex flex-col">
                            <p>_____________________________</p>
                            <p>Customer Signature</p>
                        </div>
                        <img
                            src={seal}
                            className='h-auto w-[140px]'
                            alt=""
                            style={{ transform: 'rotate(-150deg)' }}
                        />
                        <div className="flex flex-col text-center">
                            <img src={sign} alt="Authorized Signatory" className="h-20 w-auto mb-1" />
                            <p className='border-t border-black'>Authorized Signatory</p>
                        </div>
                    </div>

                    <div className="mt-6">
                        <h3 className="text-lg font-semibold">Notes</h3>
                        <p className="text-gray-600">
                            Please review the transactions carefully. If you have any questions or discrepancies, contact us at info@milescoop.com, or present the statement with the proof of payment at our offices.
                        </p>
                    </div>
                </div>
            </div>

            <button
                className="mt-8 bg-primary text-white px-6 py-2 rounded-md hover:bg-primary-dark transition"
                onClick={generatePDF}
            >
                Download 
            </button>
        </Layout>
    );
};

export default WalletStatement;
