import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import { FaGoogle, FaFacebook, FaApple, FaEye, FaEyeSlash } from 'react-icons/fa';
import logo from '../../assets/miles_logo.svg';
import image from '../../assets/login.jpg';
import Loading from '../../elements/Loading';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const API_KEY = '14CA4D6A5CE4D441CFBFB23CCB699';

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            setLoading(true);
            setError('');

            const response = await axios.post('https://api.milescoop.com/api/v1/auth/customer-signin',
                { email, password },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'api-key': API_KEY,
                    }
                }
            );

            const { token, user } = response.data;
            const userId = user.id;

            Cookies.set('access_token', token, { expires: 1 });
            Cookies.set('user_id', userId, { expires: 1 });

            navigate('/dashboard');
            window.location.reload();
        } catch (err) {
            setError('Error logging in. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleGoogleLogin = () => {
        console.log('Google Login');
    };

    const handleFacebookLogin = () => {
        console.log('Facebook Login');
    };

    const handleAppleLogin = () => {
        console.log('Apple Login');
    };

    return (
        <div className="flex justify-center items-center min-h-screen w-full">
            {loading && <Loading />}
            <div className="h-screen w-full md:w-[50%] my-auto flex flex-col items-center justify-center overflow-y-auto">
                <div className="bg-white p-8 rounded mx-auto w-full md:w-[60%]">
                    <img src={logo} className='mx-auto mb-4' alt="MileScoop Logo" />
                    <p className="text-2xl font-bold text-center">Welcome back</p>
                    <p className='text-center mb-4 text-gray-600 text-[14px]'>Login to your account here</p>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email Address</label>
                            <input
                                type="email"
                                id="email"
                                className="border-b w-full border-gray-200 bg-transparent outline-none focus:text-secondary px-3.5 py-1.5 focus:border-secondary text-[14px] text-gray-600"
                                value={email}
                                placeholder='example@example.com'
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
                            <div className="relative">
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    id="password"
                                    className="border-b w-full border-gray-200 bg-transparent outline-none focus:text-secondary px-3.5 py-1.5 focus:border-secondary text-[14px] text-gray-600"
                                    value={password}
                                    placeholder='********'
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                                <button
                                    type="button"
                                    className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-600"
                                    onClick={() => setShowPassword((prev) => !prev)}
                                >
                                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                                </button>
                            </div>
                        </div>
                        <button
                            type="submit"
                            className="w-full py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-secondary"
                            disabled={loading}
                        >
                            {loading ? 'Logging In...' : 'Login'}
                        </button>
                        <div className="w-full flex mt-1 text-[14px] items-center justify-center">
                            <p>Don't have an account?</p>
                            <Link to='/accounts/sign-up' className='text-secondary underline ml-2'>Register</Link>
                        </div>
                    </form>
                    {error && <p className="text-red-500 text-sm mt-2">{error}</p>}

                    <div className="my-6 text-center">
                        <div className="flex items-center gap-2">
                            <div className="bg-gray-300 w-full h-[1px]" />
                            <p className="text-gray-500 text-sm">or</p>
                            <div className="bg-gray-300 w-full h-[1px]" />
                        </div>
                        <div className="flex flex-col justify-center gap-4 mt-3">
                            <button
                                onClick={handleGoogleLogin}
                                className="flex items-center justify-center gap-2 p-2 text-[14px] rounded-full border bg-gray-100 hover:bg-gray-200"
                            >
                                <FaGoogle className="text-red-500 text-xl" />
                                Continue with Google
                            </button>
                            <button
                                onClick={handleFacebookLogin}
                                className="flex items-center justify-center gap-2 p-2 text-[14px] rounded-full border bg-gray-100 hover:bg-gray-200"
                            >
                                <FaFacebook className="text-blue-600 text-xl" />
                                Continue with Facebook
                            </button>
                            <button
                                onClick={handleAppleLogin}
                                className="flex items-center justify-center gap-2 text-[14px] p-2 rounded-full border bg-gray-100 hover:bg-gray-200"
                            >
                                <FaApple className="text-black text-xl" />
                                Continue with Apple
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-1/2 h-screen hidden md:flex">
                <img src={image} className='object-cover' alt="Side banner" />
            </div>
        </div>
    );
};

export default Login;
