import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import logo from '../../assets/miles_logo.svg'

const SetPassword = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const fullUrl = window.location.href;
    const url = new URL(fullUrl);

    var token = new URLSearchParams(url.search).get('token');
    const API_KEY = '14CA4D6A5CE4D441CFBFB23CCB699';

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        try {
            setLoading(true);
            setError('');

            const response = await axios.post('https://api.milescoop.com/api/v1/auth/set-customer-password',
                { password }, 
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'api-key': API_KEY,
                    }
                }
            );

            setLoading(false);
            navigate('/accounts/login');
        } catch (err) {
            setLoading(false);
            setError('Error setting password. Please try again.');
        }
    };

    return (
        <div className="flex justify-center items-center min-h-screen bg-gray-50">
            <div className="bg-white p-8 rounded shadow-md w-full max-w-md">
                <img src={logo} className='mx-auto' alt="" />
                <h2 className="text-2xl font-bold mb-6 text-center">Set Your Password</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">New Password</label>
                        <input
                            type="password"
                            id="password"
                            className="border-b w-full border-gray-200 bg-transparent outline-none focus:text-secondary px-3.5 py-1.5 focus:border-secondary text-[14px] text-gray-600"
                            value={password}
                            placeholder='********'
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">Confirm Password</label>
                        <input
                            type="password"
                            id="confirmPassword"
                            className="border-b w-full border-gray-200 bg-transparent outline-none focus:text-secondary px-3.5 py-1.5 focus:border-secondary text-[14px] text-gray-600"
                            value={confirmPassword}
                            placeholder='********'
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                        />
                    </div>
                    {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
                    <button
                        type="submit"
                        className="w-full py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-secondary"
                        disabled={loading}
                    >
                        {loading ? 'Setting Password...' : 'Set Password'}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default SetPassword;
