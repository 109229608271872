import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { FaArrowDown, FaArrowUp } from 'react-icons/fa6'
import Cookies from 'js-cookie'
import { fetchCustomerInvoices, fetchCustomerWallet } from '../utils/api/api'
import toast from 'react-hot-toast'
import { Link, useNavigate } from 'react-router-dom'

const WalletBalance = () => {
    const [wallet, setWallet] = useState([]);
    const [invoices, setInvoices] = useState([]);
    const [paymentStatus, setPaymentStatus] = useState({ message: '', dueIn: null });
    const customer_id = Cookies.get('user_id');
    const navigate = useNavigate();

    const getWallet = async () => {
        try {
            const response = await fetchCustomerWallet(customer_id);
            setWallet(response.wallet);
        } catch (error) {
            toast.error(error.message || "Request failed")
        }
    }

    const getInvoices = async () => {
        try {
            const response = await fetchCustomerInvoices(customer_id);
            setInvoices(response.invoices);
            evaluatePaymentStatus(response.invoices);
        } catch (error) {
            toast.error(error.message || "Failed to get invoices")
        }
    }

    const evaluatePaymentStatus = (invoices) => {
        const today = moment();
        let upcomingPayment = null;
        let overduePayment = null;

        invoices.forEach((invoice) => {
            const dueDate = moment(invoice.payment_due_date);

            if (invoice.payment_status === 'pending' && dueDate.isBefore(today)) {
                overduePayment = dueDate;
            }

            if (!overduePayment && invoice.payment_status === 'pending' && dueDate.isAfter(today)) {
                if (!upcomingPayment || dueDate.isBefore(upcomingPayment)) {
                    upcomingPayment = dueDate;
                }
            }
        });

        if (overduePayment) {
            setPaymentStatus({ message: 'You have overdue payments.', dueIn: overduePayment });
        } else if (upcomingPayment) {
            const daysUntilDue = upcomingPayment.diff(today, 'days');
            setPaymentStatus({
                message: `Next payment in ${daysUntilDue} day${daysUntilDue > 1 ? 's' : ''}.`,
                dueIn: upcomingPayment,
            });
        } else {
            setPaymentStatus({ message: 'No pending payments.', dueIn: null });
        }
    };

    useEffect(() => {
        getWallet();
        getInvoices();
    }, []);

  return (
      <div className="flex flex-col w-full md:w-[35%] md:mt-[36px]">
          <div className="w-full gap-4 flex flex-col bg-white  border border-gray-100 p-6 mb-4 rounded-lg">
              <div className="flex w-full items-center justify-between">
                  <p className="text-primary font-medium text-[18px]">Wallet balance</p>
                  <p className="text-gray-600 text-[14px]">{moment().format('MMM DD, YYYY')}</p>
              </div>
              <p className="text-primary font-semibold text-[22px]">
                  {Number(wallet?.balance).toLocaleString("KES")}
                  <span className="text-[13px] ml-2">KES</span>
              </p>
              <div className="flex items-center w-full justify-between">
                  <div className="flex items-center gap-2">
                      <div className="bg-green-500 text-white h-10 w-10 flex items-center justify-center">
                          <FaArrowDown size={24} />
                      </div>
                      <div className="flex flex-col">
                          <p className="text-primary font-medium text-[15px]">
                              {/* 68, 787 */}
                              <span className="text-[13px] ml-2">KES</span>
                          </p>
                          <p className="text-[12px] text-gray-500">Money In</p>
                      </div>
                  </div>
                  <div className="flex items-center gap-2">
                      <div className="bg-red-500 text-white h-10 w-10 flex items-center justify-center">
                          <FaArrowUp size={24} />
                      </div>
                      <div className="flex flex-col">
                          <p className="text-primary font-medium text-[15px]">
                              {/* 68, 787 */}
                              <span className="text-[13px] ml-2">KES</span>
                          </p>
                          <p className="text-[12px] text-gray-500">Amount deducted</p>
                      </div>
                  </div>
              </div>
              <Link to='/dashboard/statement' className="flex items-cneter justify-center text-center w-full bg-primary text-white rounded-md py-1.5 text-[14px] font-medium">Request statement</Link>
          </div>
          <div className="bg-white flex flex-col rounded-lg gap-2 w-full items-center justify-center p-4 border border-gray-100">
              <p className="text-[22px] text-primary font-semibold">{paymentStatus.message}</p>
              {paymentStatus.dueIn && (
                  <p className="text-red-500 text-[14px]">payment due on {paymentStatus.dueIn.format('DD MMM, YYYY')}</p>
              )}
              <button onClick={() => navigate('/dashboard/orders')} className="rounded-md bg-secondary w-fit text-[14px] font-medium text-white py-1.5 px-6">
                  Review records
              </button>
          </div>
          <div className="flex flex-col p-4 mt-4 rounded-lg gap-3 bg-white w-full  border border-gray-100">
              <div className="flex justify-between items-center w-full">
                  <p className="text-[18px] font-medium">Reminders</p>
                  <div className="">view all</div>
              </div>
              <div className="flex items-center w-full justify-between">
                  <div className="flex items-center gap-2">
                      <div className="bg-red-100 rounded-full flex items-center h-[40px] p-2 w-[40px] justify-center text-red-500 text-[24px] font-bold">
                          !
                      </div>
                      <div className="flex flex-col">
                          <p className="text-[16px] text-black font-semibold">Site viewing reminder</p>
                          <p className="text-gray-700 text-[14px]">Wisdom garden, kilifi</p>
                      </div>
                  </div>
                  <p className="text-[14px] font-light text-black">24th Oct, 2024</p>
              </div>
          </div>
          <div className="flex flex-col p-4 mt-4 rounded-lg gap-3 bg-white w-full">
              <p className="text-[18px] font-medium">Recent activities</p>
          </div>
      </div>
  )
}

export default WalletBalance